import React from "react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import cx from "classnames";
import {
  navbar,
  banner,
  stickyBlocks,
  contactUsCard,
  navBarContact,
  whatsAppContact,
  whatsAappUrlForPage,
  announcementStrip,
} from "utils/renderBlocks";
import FontLoad from "components/FontLoad";
import { GuestOptionsBar } from "components/GuestOptionsBar";
import { WhatsAppFloat } from "components/Design/FloatingButton";
import RoomSelectionSearchDrawer from "components/Design/Drawer/RoomSelectionSearchDrawer";
import { RenderBlock } from "components/RenderBlock";
import { FOOTER, NAVBAR } from "store/constants/blockThemes";
import { RoomLayout } from "components/RoomLayout";
import { StickyCta } from "components/StickyCta";
import "components/Layout/style.less";
import { FooterBlock } from "components/Blocks/FooterBlock";
import { transformObject } from "utils/transformv4";
import PageContext from "contexts/PageContext";
import { getHotel } from "utils/getHotel";

const RoomPage = ({ data, pageContext }) => {
  const {
    allNavigationMenuJson: { nodes: navigations },
    cms,
    allSocialMediaJson: { nodes: allSocialMedia },
  } = data;

  const {
    hotelsByLocale,
    destinationImagesSections,
    supportDrawers,
    lvxLogo: [lvxLogo],
    hotelPropertyBali,
    page: {
      blocks,
      room_types: [roomType],
    },
  } = transformObject(cms);
  const { language } = useI18next();

  const navbarBlock = navbar(blocks);
  const bannerBlock = banner(blocks);
  const announcementStripBlock = announcementStrip(blocks);
  const stickyBlock = stickyBlocks(blocks);
  const contactUsBlock = contactUsCard(blocks);
  const whatsAppContactBlock = whatsAppContact(blocks);
  const destination = navbarBlock?.destination;
  const navbarContactBlock = navBarContact(blocks);
  const hotel = getHotel(navbarBlock, hotelsByLocale);

  const formattedWhatsAppUrl = whatsAappUrlForPage({
    hotel,
    destination: navbarBlock.destination,
    whatsAppContactBlock,
  });
  
  return (
    <>
      <PageContext.Provider
        value={{
          roomDetail: roomType,
        }}>
        {(language === "zh" || language === "ja") && <FontLoad />}
        <div
          className={cx("antialiased", {
            /* helps in wrapping words at spaces for Japanese language */
            "font-ja wrap-word": language === "ja",
            "font-zh": language === "zh",
            "font-ko": language === "ko",
          })}>
          <div className="hidden lg:block lg:sticky lg:top-0 lg:z-100">
            <GuestOptionsBar
              destinationCards={destinationImagesSections[0].images}
              hotel={navbarBlock.destination}
              cartCount={0}
              navigationHotel={navbarBlock.hotel?.name}
              hotelPropertyBali={hotelPropertyBali}
            />
          </div>
          <div className="antialiased">
            <RenderBlock
              key={`navbar-${navbarBlock.id}`}
              block={{
                ...navbarBlock,
                allHotels: hotelsByLocale,
                navigations,
                destinationImagesSections,
                supportDrawers,
                theme: NAVBAR,
                formattedWhatsAppUrl,
                navbarContactBlock,
                announcementStrip: announcementStripBlock,
                roomId: roomType.id,
              }}
            />
            {bannerBlock && (
              <RenderBlock
                key={`${bannerBlock.__typename}:${bannerBlock.id}`}
                block={bannerBlock}
              />
            )}
            <div className="overflow-x-hidden">
              <RoomLayout
                room_type={roomType}
                blocks={blocks}
                pageContext={pageContext}
              />
            </div>

            <FooterBlock
              key={`footer-${navbarBlock.id}`}
              {...{
                ...navbarBlock,
                allHotels: hotelsByLocale,
                allSocialMedia,
                navigations,
                destinationImagesSections,
                supportDrawers,
                theme: FOOTER,
                lvxLogo,
              }}
            />
            <WhatsAppFloat link={formattedWhatsAppUrl} />
            <RoomSelectionSearchDrawer
              location={destination}
              hotelCode={hotel.hotel_code}
              cta={(onClick, sticky) => (
                <div
                  className={cx(
                    "fixed lg:hidden bottom-0 w-full z-20 transition-all duration-500",
                    {
                      "opacity-0 invisible": !sticky,
                      "opacity-100 visible": sticky,
                    }
                  )}>
                  <StickyCta
                    stickyBlock={stickyBlock}
                    contactUsBlock={contactUsBlock}
                    onClick={onClick}
                    destination={destination}
                    whatsAppUrl={formattedWhatsAppUrl}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </PageContext.Provider>
    </>
  );
};

export default RoomPage;

// TODO: We need a better way to get check-in and check-out time
export const query = graphql`
  query RoomTemplate($id: ID!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson {
      ...LocalNavigation
    }
    allSocialMediaJson {
      nodes {
        hotel
        code
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      ...PageLayout
      page: pageByLocale(id: $id, language: $language) {
        data {
          id
          attributes {
            slug
            layout
            ...CmsPageDynamicBlocks_v4
            room_types {
              ...CmsRoomTypeCollection_v4
            }
          }
        }
      }
    }
  }
`;
